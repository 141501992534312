import { CheckoutV1 } from '@getjust/api-schema';
import { getCMSInterceptors } from '@getjust/cms-interceptors';
import { InterceptorInterface } from '@getjust/cms-interceptors/dist/types';
import { Cart } from '@getjust/gateway';
import axios from 'axios';
import { useMemo } from 'react';

import { useShop } from '../queries';

// Default functions
const redirectToOrderPage = (options: { cart?: Cart; order: CheckoutV1.OrdersGetStatusResponseBody }) => {
  if (options?.order?.outcome === 'ready') {
    const url = new URL(options?.order?.url);

    if (!url.searchParams.get('metadata')) {
      window.location.href = options?.order?.url;
    }

    const data: { [key: string]: string } = {};

    for (const [key, value] of url.searchParams.entries()) {
      data[key] = value;
    }

    axios.post(`${url.hostname}${url.pathname}`, data);
  }
};

export const useCMSInterceptors = (): InterceptorInterface => {
  const { data: shop } = useShop();
  const cmsHooks = useMemo(() => {
    if (!shop) {
      return {};
    }
    return getCMSInterceptors(shop?.platformType);
  }, [shop]);

  return {
    redirectToOrderPage,
    ...cmsHooks,
  };
};
